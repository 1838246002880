import React from "react";

import shopifyLogo from "../images/shopify.svg";

const Intro = () => (

  <div>
    
    {/*
    <script>
      kwesforms.init();
    </script>
    */}

    <div className="py-20 xl:py-32 2xl:py-40" id="contact">

      <div className="container mb-8 lg:mb-16 xl:mb-24">
        <h2 className="font-bold text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-8xl 2xl:text-8-5xl">
          {/* Let's work <span className="text-primary text-gradient">together</span> */}
          <span className="text-primary text-gradient">Say</span> hello
        </h2>
      </div>

      <div className="container xl:max-w-7xl text-lg xl:text-xl">

        <div className="flex flex-col flex-wrap md:flex-row md:-mx-8 overflow-hidden">
          <div className="mb-16 md:mb-0 md:w-1/2 md:px-8">

            <div className="mb-4 lg:mb-8">
              <h2 className="inline-block text-sm 2xl:text-base font-medium mr-4 uppercase w-12 lg:w-16">
                Social
              </h2>
              <div className="text-lg xl:text-xl 2xl:text-2xl inline font-light">
                <a href="http://www.linkedin.com/in/tomaszbujnowicz" className="link inline-flex items-center hover:text-primary relative pb-2 text-gradient">
                  LinkedIn
                  <svg className="w-4 h-4 ml-2" width="16" height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>
                </a>
              </div>
            </div>

            <div>
              <h2 className="inline-block text-sm 2xl:text-base font-medium mr-4 uppercase w-12 lg:w-16">
                Email
              </h2>
              <div className="text-lg xl:text-xl 2xl:text-2xl inline font-light">
                <a href="mailto:hi@tomaszbujnowicz.com" className="link inline-flex items-center hover:text-primary relative pb-2 text-gradient">
                  hi@tomaszbujnowicz.com
                  <svg className="w-4 h-4 ml-2" width="16" height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>
                </a>
              </div>
            </div>

          </div>
      
          {/*
          <div className="md:w-1/2 md:px-8">

            <div className="mb-8 lg:mb-12 xl:mb-16">
              <div className="text-lg xl:text-xl 2xl:text-2xl inline font-light">
                Tell me more about your Shopify business.
              </div>
            </div>
            <form className="kwes-form" action="https://kwes.io/api/foreign/forms/jc0MtuhPFi9nN3cQaoPP">

              <div className="mb-8 outline relative">
                <input type="text" name="name" placeholder="Name *" className="block p-4 w-full border-b border-black" rules="required|max:255" />
                <label htmlFor="name" className="absolute top-0 p-4 -z-1 duration-300  text-sm">Name</label>
              </div>

              <div className="mb-8 outline relative">
                <input type="email" name="email" placeholder="E-mail *" className="block p-4 w-full border-b border-black" rules="required|email" />
                <label htmlFor="email" className="absolute top-0 p-4 -z-1 duration-300 text-sm">E-mail</label>
              </div>

              <div className="mb-8 outline relative">
                <textarea name="message" id="message" className="block p-4 w-full border-b border-black" rows="4" rules="required|max:1000" placeholder="Tell me more about your project"></textarea>
                <label htmlFor="message" className="absolute top-0 p-4 -z-1 duration-300 text-sm">Tell me more about your Shopify business</label>
              </div>

              <div className="text-right pb-4">
                <button type="submit" className="btn">Submit</button>
              </div>
            </form>

          </div>
          */}

        </div>
      </div>

    </div>

    <div className="bg-gray-50">
      <div className="container text-center py-12 font-light ">
        <div>
          ©{new Date().getFullYear()} Tomasz Bujnowicz
        </div>
        <h4 className="pt-2 pb-4">
          Shopify Developer with a strong focus on user experience (UX)
        </h4>
        <div>
          <img src={shopifyLogo} alt="Shopify" className="w-24 mx-auto lazyload" />
        </div>
      </div>
    </div>

  </div>

)

export default Intro
