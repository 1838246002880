import React from "react";

const Intro = () => (

    <div>

      <div className="container flex flex-col sm:flex-row items-center justify-between leading-none py-8 md:py-8 mb-4 sm:mb-16 lg:mb-20 xl:mb-32 text-center sm:text-left">
        <div className="mb-8 sm:mb-0">
          Tomasz Bujnowicz
        </div>
        <div>
          <a href="#contact" className="btn">
            Say hello
          </a>
        </div>
      </div>

      <div className="container mb-12 sm:mb-16 md:mb-20 lg:mb-32 xl:mb-40 2xl:mb-64 text-center md:text-left">
        <h1 className="font-alt font-black text-5xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-8-5xl 2xl:text-9xl mb-8 sm:mb-12 max-w-6xl">
          {/* I build <strong className="font-black text-primary text-gradient">Shopify</strong> stores that convert. */}
          I build <strong className="font-black text-primary text-gradient">Shopify</strong> stores that convert.
        </h1>

        {/* <h2 className="text-xl md:text-2xl xl:text-3xl font-ligh">
          An experienced Shopify Developer specializing in building and improving Shopify stores.
        </h2> */}
        <h2 className="text-xl md:text-2xl xl:text-3xl font-light mb-2">
          Shopify Developer specializing in building and improving Shopify stores.
        </h2>
        <h3 className="text-lg md:text-xl xl:text-2xl font-light">
          I am part of the experienced Shopify Plus agency based in Copenhagen.
        </h3>
      </div>

      <div className="container mb-12 sm:mb-16 md:mb-20 lg:mb-32 xl:mb-40 2xl:mb-64 text-center md:text-left hidden">
        <div className="flex flex-col xl:flex-row items-center xl:items-end xl:-mx-4">
          <div className="xl:w-3/4 mb-8 sm:mb-12 xl:mb-0 xl:px-2">
            {/* <h1 className="font-alt font-black text-4xl sm:text-6xl xl:text-7xl 2xl:text-8-5xl">
              Building conversion-focused <span className="text-primary text-gradient">Shopify</span> stores.
            </h1> */}
            <h1 className="font-alt font-black text-5xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-8-5xl 2xl:text-9xl">
              <span className="text-primary text-gradient">Shopify</span> stores that convert.
              {/* Building conversion-focused <span className="text-primary text-gradient">Shopify</span> stores. */}
            </h1>

          </div>
          <div className="xl:w-1/4 xl:px-2">
            <div className="max-w-md">

              <div className="font-medium mr-2">
                Hi, I'm Tomasz,
              </div>

              <h2 className="text-xl xl:text-2xl font-light md:mb-2">
                An experienced Shopify Developer specializing in building and improving Shopify stores.
                {/* A Shopify Developer specializing in developing and improving Shopify stores. */}
              </h2>

              {/* <h2 className="inline text-sm 2xl:text-base font-medium mr-2 uppercase">
                Tomasz
              </h2>
              <div className="text-xl xl:text-2xl 2xl:text-2xl inline font-light">
                is a European based Shopify Developer specializing in developing and improving Shopify stores.
              </div> */}

              {/* Tomasz is a European based Shopify Developer specializing in developing and improving Shopify stores. Focused on an ecommerce experiences that convert. */}

            </div>
          </div>
        </div>
      </div>

    </div>
)

export default Intro
