import React from "react";
import work1212gateway from "../images/work-1212gateway.jpg";
import workSefton from "../images/work-sefton.jpg";
import workKrave from "../images/work-krave.jpg";
import workOther from "../images/work-other.jpg";

const Work = () => (

  <div>

    {/* <div className="container max-w-7xl text-center">
      <div className="mb-6 lg:mb-8 text-xl xl:text-2xl 2xl:text-2xl font-light">
        Working with people from around the globe and successfully bringing ideas to life.
      </div>
    </div> */}

    <div className="md:py-8 lg:py-12 md:bg-gray-50">
      <div className="max-w-1920 mx-auto">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 lg:w-2/5 flex flex-col order-2 md:order-1">
            <div className="container py-8 md:h-full md:flex md:flex-col md:justify-center">
              <h3 className="
                relative
                font-semibold
                mb-6 md:mb-8 lg:mb-12 xl:mb-16
                text-3xl md:text-4xl lg:text-5xl xl:text-6xl
                md:-mr-20 lg:-mr-24 md:text-right
                xl:-mr-32">
                  The 1212 Gateway
              </h3>
              <div className="max-w-md lg:ml-auto xl:pr-12">
                <h2 className="md:mb-4 uppercase text-sm tracking-wide opacity-50">
                  Custom Shopify Theme Development
                </h2>
                <p className="mb-4 font-light text-lg xl:text-xl 2xl:text-2xl">
                  Founded by Catherine Paiz McBroom, 1212 Gateway goes beyond the reach of the average clean beauty skincare brand.
                  {/* 1212 Gateway was built upon the values and experience of founder, Catherine McBroom. */}
                </p>
                <p>
                  <a href="https://1212gateway.com/" className="link inline-flex items-center hover:text-primary relative pb-2 text-gradient text-lg xl:text-xl">
                    View project
                    <svg className="w-4 h-4 ml-2" width="16" height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 lg:w-3/5 order-1 md:order-2">
            <img data-src={work1212gateway} className="lazyload" alt="1212 Gateway" />
          </div>
        </div>
      </div>
    </div>

    <div className="md:py-8 lg:py-12">
      <div className="max-w-1920 mx-auto">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 lg:w-3/5">
            <img data-src={workKrave} className="lazyload" alt="KraveBeauty" />
          </div>
          <div className="md:w-1/2 lg:w-2/5 flex flex-col">
            <div className="container py-8 md:h-full md:flex md:flex-col md:justify-center">
              <h3 className="
                relative
                font-semibold
                mb-6 md:mb-8 lg:mb-12 xl:mb-16
                text-3xl md:text-4xl lg:text-5xl xl:text-6xl
                md:-ml-20 lg:-ml-24
                xl:-ml-32">
                  KraveBeauty
              </h3>
              <div className="max-w-md lg:mr-auto xl:pl-12">
                <h2 className="md:mb-4 uppercase text-sm tracking-wide opacity-50">
                  Shopify Homepage Customization
                </h2>
                <p className="mb-4 font-light text-lg xl:text-xl 2xl:text-2xl ">
                  Founded by Liah Yoo to create skincare basics that work your skin, not against it.
                </p>
                <p>
                  <a href="https://kravebeauty.com/" className="link inline-flex items-center hover:text-primary relative pb-2 text-gradient text-lg xl:text-xl">
                    View project
                    <svg className="w-4 h-4 ml-2" width="16" height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="md:py-8 lg:py-12 md:bg-gray-50">
      <div className="max-w-1920 mx-auto">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 lg:w-2/5 flex flex-col order-2 md:order-1">
            <div className="container py-8 md:h-full md:flex md:flex-col md:justify-center">
              <h3 className="
                relative
                font-semibold
                mb-6 md:mb-8 lg:mb-12 xl:mb-16
                text-3xl md:text-4xl lg:text-5xl xl:text-6xl
                md:-mr-20 lg:-mr-24 md:text-right
                xl:-mr-32">
                Sefton Fashion
              </h3>
              <div className="max-w-md lg:ml-auto xl:pr-12">
                <h2 className="md:mb-4 uppercase text-sm tracking-wide opacity-50">
                 Custom Shopify Theme Development
                </h2>
                <p className="mb-4 font-light text-lg xl:text-xl 2xl:text-2xl ">
                  Sefton is a leading independent menswear retailer in Islington, London.
                </p>
                <p>
                  <a href="https://seftonfashion.com/" className="link inline-flex items-center hover:text-primary relative pb-2 text-gradient text-lg xl:text-xl">
                    View project
                    <svg className="w-4 h-4 ml-2" width="16" height="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 lg:w-3/5 order-1 md:order-2">
            <img data-src={workSefton} className="lazyload" alt="Sefton Fashion" />
          </div>
        </div>
      </div>
    </div>

    <div className="md:py-8 lg:py-12">
      <div className="max-w-1920 mx-auto">
        <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 lg:w-3/5">
            <img data-src={workOther} className="lazyload" alt="Shopify Development" />
          </div>
          <div className="md:w-1/2 lg:w-2/5 flex flex-col">
            <div className="container py-8 md:h-full md:flex md:flex-col md:justify-center">
              <h3 className="
                relative
                font-semibold
                mb-6 md:mb-8 lg:mb-12 xl:mb-16
                text-3xl md:text-4xl lg:text-5xl xl:text-6xl
                md:-ml-20 lg:-ml-24
                xl:-ml-32">
                  Other Work
              </h3>
              <div className="max-w-md lg:mr-auto xl:pl-12">
                <p className="mb-4 font-light text-lg xl:text-xl 2xl:text-2xl ">
                  Full portfolio of my work available upon request.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

)

export default Work
