import * as React from "react"
import Helmet from 'react-helmet'
import SEO from "../components/seo";
import share from "../images/share.png";

import Intro from "../components/intro";
import Work from "../components/work";
import Contact from "../components/contact";
import Services from "../components/services";
// import Convert from "../components/convert";

// markup
const IndexPage = () => {
  return (
    <div>

      <SEO
        keywords={[`shopify`, `shopify development`, `shopify developer`, `shopify UX`, `shopify improvements`]}
        title='Shopify Developer. Shopify stores that convert.'
      />

      <Helmet
        bodyAttributes={{
          class: 'antialiased'
        }}
      />

      <Helmet>
        <script type='application/ld+json'>
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Shopify Developer",
            "description": "Shopify Developer. Shopify stores that convert.",
            "url": "https://www.tomaszbujnowicz.com/"
          }
        `}
        </script>
        <link rel="canonical" href="https://www.tomaszbujnowicz.com" />
        <meta property="og:image" content={share} />
      </Helmet>

      <Intro />

      {/*
        Tomasz is a European based Shopify Developer specializing in developing and improving Shopify stores. Focused on user experience and ecommerce experiences that convert.
      */}

      <Work />

      <Services />

      {/* <Convert /> */}

      <Contact />

    </div>
  )
}

export default IndexPage
