import React from "react";

const Services = () => (

  <div className="py-20 xl:py-32 2xl:py-40 bg-gray-50">

    <div className="container mb-8 lg:mb-16 xl:mb-24">
      <h2 className="font-bold text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-8xl 2xl:text-8-5xl">
        <span className="text-primary text-gradient">Shopify</span> services
      </h2>

    </div>

    <div className="container xl:max-w-7xl text-lg xl:text-xl">

      <div className="mb-8 lg:mb-12 xl:mb-16">
        <div className="text-lg xl:text-xl 2xl:text-2xl inline font-light">
          I partner with Shopify Plus agencies, creative people, and successfully bring ideas to life.
        </div>
      </div>

      <div className="mb-8 xl:mb-12 2xl:mb-16">
        <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-medium uppercase mb-1 xl:mb-2">
          Shopify Theme Development
        </h2>
        <div className="text-lg xl:text-xl 2xl:text-2xl font-light">
          with a strong focus on user experience (UX).
        </div>
      </div>

      <div className="mb-8 xl:mb-12 2xl:mb-16">
        <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-medium uppercase mb-1 xl:mb-2">
          Shopify Customization
        </h2>
        <div className="text-lg xl:text-xl 2xl:text-2xl font-light">
          improving Shopify stores.
        </div>
      </div>

      <div className="mb-8 xl:mb-12 2xl:mb-16">
        <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-medium uppercase mb-1 xl:mb-2">
          Shopify Conversion Rate Optimization
        </h2>
        <div className="text-lg xl:text-xl 2xl:text-2xl font-light">
          increasing conversion rates.
        </div>
      </div>

      {/* <div>
        <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-medium uppercase mb-1 xl:mb-2">
          Improving Google's Core Web Vital metrics
        </h2>
        <div className="text-lg xl:text-xl 2xl:text-2xl font-light">
          The next official Google ranking factor.
        </div>
      </div> */}

    </div>



    <div className="container hidden">
      <div className="mb-2 md:mb-4 xl:mb-6 md:pl-8 lg:pl-12 xl:pl-24 row-line">
        <h2 className="inline-block font-medium text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl headline-line md:px-8 2xl:px-12">
          Shopify Theme Development
        </h2>
      </div>
      <div className="mb-2 md:mb-4 xl:mb-6 md:pl-16 lg:pl-24 xl:pl-48 row-line">
        <h2 className="inline-block font-medium text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl headline-line md:px-8 2xl:px-12">
          Shopify Conversion Rate Optimization
        </h2>
      </div>
      <div className="mb-2 md:mb-4 xl:mb-6 md:pl-24 lg:pl-36 xl:pl-72 row-line">
        <h2 className="inline-block font-medium text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl headline-line md:px-8 2xl:px-12">
          Shopify User Experience Improvements
        </h2>
      </div>
    </div>

  </div>

)

export default Services
